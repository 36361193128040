<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useReportStore } from "@/composables/store/useReportStore";
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

// Dependencies
const route = useRoute();
const router = useRouter();

const socialConnectionStore = useSocialConnection();

// Refs and Stores
const { googleAdsAccounts, googleAnalyticsAccounts } = storeToRefs(
  useReportStore()
);
const authStore = useAuthStore();

// State Management
const state = ref({
  agencyId: null,
  clientId: null,
  urlId: null,
  urlToken: null,
  status: route.query.status,
  type: null, // Default to "google" if type is not specified
});

const selectedAccounts = ref([]);
const selectedGoogleAnalyticsAccounts = ref([]);
const loading = ref({
  googleAds: false,
  googleAnalytics: false,
  submitAll: false,
});

// Computed Properties
const shouldShowGoogleAds = computed(
  () => state.value.type === "google_ads" || state.value.type === "google"
);
const shouldShowGoogleAnalytics = computed(
  () => state.value.type === "google_analytics" || state.value.type === "google"
);

const redirectRoute = computed(() => {
  const { status } = state.value;
  if (status === "onboarding")
    return { name: "onboarding", query: { activeTab: 1 } };
  if (status === "public") {
    return {
      name: "pages-connect-id",
      params: { id: state.value.urlId },
      query: { token: state.value.urlToken },
    };
  }
  return authStore.company.company_type === "agency"
    ? { name: "pages-request-access" }
    : { name: "pages-client-request-access" };
});

// Utilities
const parseRouteState = () => {
  try {
    const parsedState = JSON.parse(decodeURIComponent(route.query?.state));
    state.value = {
      ...state.value,
      agencyId: parsedState.agency_id,
      clientId: parsedState.client_id,
      urlId: parsedState.url_id,
      urlToken: parsedState.url_token,
      type: parsedState.type,
      serviceToken: route.query.service_token,
    };
  } catch (error) {
    console.error("Error parsing route state:", error);
  }
};

const fetchConnectedAccounts = async () => {
  const accountId = state.value.clientId || state.value.agencyId;
  await useReportStore().getConnectedAccountsByServiceToken(
    state.value.serviceToken
  );

  selectedAccounts.value =
    googleAdsAccounts.value && googleAdsAccounts.value.length
      ? googleAdsAccounts.value.map((account) => account.id)
      : [];
  selectedGoogleAnalyticsAccounts.value =
    googleAnalyticsAccounts.value && googleAnalyticsAccounts.value.length
      ? googleAnalyticsAccounts.value.map((account) => account.id)
      : [];
};

const handleAction = async (type, accountsRef, platform) => {
  if (!accountsRef.value.length) return;
  loading.value[type] = true;
  try {
    await useReportStore().updateConnectedAccountStatus(
      state.value.serviceToken,
      accountsRef.value,
      state.value.clientId,
      state.value.status
    );
  } catch (error) {
    console.error(`Error updating ${platform} accounts:`, error);
  } finally {
    loading.value[type] = false;
  }
};

// Lifecycle
onMounted(async () => {
  parseRouteState();
  await fetchConnectedAccounts();
});

// Event Handlers
const handleSubmitAll = async () => {
  loading.value.submitAll = true;
  try {
    if (shouldShowGoogleAds.value) {
      handleAction("googleAds", selectedAccounts, "googleAds");
    } else if (shouldShowGoogleAnalytics.value) {
      handleAction(
        "googleAnalytics",
        selectedGoogleAnalyticsAccounts,
        "googleAnalytics"
      );
    }
    router.push(redirectRoute.value);
  } finally {
    loading.value.submitAll = false;
  }
};
</script>

<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="bg-white shadow-md rounded-lg p-6 w-full max-w-xl">
      <h4 class="text-lg font-medium text-gray-800 mb-4">
        🎉 Welcome! You're Almost There!
      </h4>
      <p class="text-gray-600 mb-6">
        By connecting your Google Ads and Google Analytics accounts, you're
        enabling the agency to create data-driven strategies tailored to your
        business. The accounts you select here will be used to generate powerful
        insights and performance reports. Let's get started!
      </p>

      <form @submit.prevent="handleSubmitAll" class="space-y-6">
        <!-- Google Ads -->
        <div v-if="shouldShowGoogleAds">
          <AppAutocomplete
            v-model="selectedAccounts"
            class="w-full"
            :items="googleAdsAccounts"
            item-title="name"
            item-value="account_id"
            placeholder="Select Google Ads Accounts"
            label="Google Ads Accounts"
            multiple
            clearable
          />
        </div>

        <!-- Google Analytics -->
        <div
          v-if="shouldShowGoogleAnalytics"
          class="flex items-center gap-[20px]"
        >
          <AppAutocomplete
            v-model="selectedGoogleAnalyticsAccounts"
            class="w-full"
            :items="googleAnalyticsAccounts"
            item-title="name"
            item-value="id"
            placeholder="Select Google Analytics Accounts"
            label="Google Analytics Accounts"
            multiple
            clearable
          />
        </div>

        <!-- Submit All -->
        <VBtn
          class="w-full mt-6 bg-green-600 text-white font-medium py-3 rounded-md hover:bg-green-700 transition duration-150"
          :loading="loading.submitAll"
          @click="handleSubmitAll"
        >
          Finish Setup
        </VBtn>
      </form>
    </div>
  </div>
</template>
