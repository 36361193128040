import { useAuthStore } from "@/composables/store/useAuthStore";
import { useCategoryStore } from "@/composables/store/useCategoryStore";
import { LOCALSTORAGE } from "@/utils/constants";

export const setupGuards = (router) => {
  router.beforeEach(async (to, from, next) => {
    // Check for apiKey in query params
    if (to.query.api_key) {
      const { regenerateTokenByApiKey } = useAuthStore();

      await regenerateTokenByApiKey(to.query.api_key);
    }

    // Check authentication status early
    const isLoggedIn = checkAuthStatus();

    // Handle public routes first
    if (to.meta.public) {
      return next();
    }

    // Handle unauthenticated-only routes
    if (to.meta.unauthenticatedOnly) {
      return isLoggedIn ? next("/") : next();
    }

    // Handle protected routes
    if (!isLoggedIn) {
      return next({
        name: "login",
        query: {
          to: to.fullPath !== "/" ? to.fullPath : undefined,
        },
      });
    }

    // Initialize data for authenticated users
    const { initAuthData } = useAuthStore();
    const { initCategories } = useCategoryStore();

    await Promise.all([initAuthData(), initCategories()]);

    next();
  });
};

function checkAuthStatus() {
  return !!(
    localStorage.getItem(LOCALSTORAGE.USER_ID) &&
    localStorage.getItem(LOCALSTORAGE.COMPANY_ID) &&
    localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN)
  );
}
