<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const authStore = useAuthStore();

// Get the company details
// Run every 1 seconds and check if the current subscription key is different from the previous one
// Loop max 5 times
// If it is different, redirect to the appropriate page

const checkSubscriptionChange = async () => {
  let count = 0;
  const interval = setInterval(async () => {
    try {
      const isValid = await authStore.isValidSubscription();
      console.log(isValid);
      console.log(count);

      if (count >= 2) {
        router.push({
          name: "onboarding",
          query: {
            error: "subscription_not_updated",
          },
        });
        clearInterval(interval);
      } else if (!isValid) {
        console.error("Subscription is not valid");
        await authStore.regenerateToken();
        clearInterval(interval);
        router.push({
          name: "onboarding",
        });
      } else {
        count++;
      }
    } catch (error) {
      console.error("Error checking subscription:", error);
      clearInterval(interval);
    }
  }, 3000);
};

onMounted(async () => {
  await checkSubscriptionChange();
});
</script>

<template>
  <div class="text-center" style="margin-top: 200px">
    <h1 class="mb-10">Checking Subscription, Redirecting back to app</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
