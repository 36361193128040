import { sendGraphQLRequest } from "@/utils/axios";
import { LOCALSTORAGE } from "@/utils/constants";
import { defineStore } from "pinia";

const CategoryType = {
  PROJECT: "project",
  DOCUMENT_TEMPLATE: "document_template",
  PROJECT_STATUS: "project_status",
};

export const useCategoryStore = defineStore({
  id: "categoryStore",
  state: () => ({
    isCategoryLoading: false,
    allCategories: [],
    categories: [],
    templateCategories: [],
    questions: [],
    categoriesPagination: {
      first: 10,
      after: null,
      page: 1,
      search: null,
      sortBy: null,
      isLoading: false,
    },
    categoryForm: {
      name: "",
      description: "",
      company_fk: localStorage.getItem(LOCALSTORAGE.COMPANY_ID),
      type: "category",
    },
    isAddClientLoading: false,
  }),
  actions: {
    // Get all categories
    async getAllCategories() {
      const query = `
            query GetCategories($companyId: uuid!, $sortBy: [categories_order_by!]) {
                categories(
                    where: {
                        _or: [
                            {company_fk: {_eq: $companyId}},
                            {company_fk: {_is_null: true}}
                        ]
                    },
                order_by: $sortBy
                ) {
                category_pk
                name
                type
                key
                }  
            }
            `;
      this.isCategoryLoading = true;
      const data = await sendGraphQLRequest(query, {
        sortBy: this.categoriesPagination.sortBy,
        companyId: localStorage.getItem(LOCALSTORAGE.COMPANY_ID),
      });
      this.allCategories = data.data.categories;
      this.isCategoryLoading = false;
    },

    async getBriefQuestions() {
      const query = `
           query GetBriefQuestions {
            categories(where: {type: {_eq: "brief_question"}}){
             name
             key
             }
           }
            `;
      this.isCategoryLoading = true;
      const data = await sendGraphQLRequest(query, {});
      this.questions = data.data.categories;
      this.isCategoryLoading = false;
      return data.data.categories;
    },

    async getTemplateCategories() {
      const query = `
      query getTemplateCategories {
      categories(where: {type: {_eq: "document_template"}}) {
        name
        category_pk
      }
    }
            `;
      this.isCategoryLoading = true;
      const data = await sendGraphQLRequest(query, {});
      this.templateCategories = data.data.categories;
      this.isCategoryLoading = false;
      return data.data.categories;
    },

    async getCategories() {
      const query = `
            query GetCategories($companyId: uuid!, $sortBy: [categories_order_by!]) {
                categories(
                    where: {
                        _or: [
                            {company_fk: {_eq: $companyId}},
                            {company_fk: {_is_null: true}}
                        ]
                    },
                order_by: $sortBy
                ) {
                category_pk
                name
                description
                company_fk
                type
                }
            }
            `;
      this.isCategoryLoading = true;
      const data = await sendGraphQLRequest(query, {
        sortBy: this.categoriesPagination.sortBy,
        companyId: localStorage.getItem(LOCALSTORAGE.COMPANY_ID),
      });
      this.categories = data.data.categories;
      this.isCategoryLoading = false;
    },
    async addCategory() {
      try {
        this.isAddClientLoading = true;
        const query = `
                mutation AddCategory($category: [categories_insert_input!]!) {
                insert_categories(objects: $category) {
                    returning {
                    id
                    name
                    description
                    company_fk
                    type
                    }
                }
                }
            `;
        const data = await sendGraphQLRequest(query, {
          category: [this.categoryForm],
        });
        this.categories = [
          ...this.categories,
          ...data.data.insert_categories.returning,
        ];
        this.isAddClientLoading = false;
      } catch (error) {
        this.isAddClientLoading = false;
      }
    },
    async deleteCategory(categoryId) {
      const query = `
            mutation DeleteCategory($categoryId: uuid!) {
                delete_categories_by_pk(id: $categoryId) {
                id
                }
            }
            `;
      await sendGraphQLRequest(query, {
        categoryId,
      });
      this.categories = this.categories.filter(
        (category) => category.id !== categoryId
      );
    },
    async initCategories() {
      if (this.categories.length === 0) {
        await this.getAllCategories();
      }
    },
  },
  getters: {
    projectCategories: (state) => {
      return state.allCategories
        .filter((category) => category.type === CategoryType.PROJECT)
        .map((category) => {
          return {
            title: category.name,
            key: category.key,
            value: category.category_pk,
          };
        });
    },
    documentTemplateCategories: (state) => {
      return state.allCategories
        .filter((category) => category.type === CategoryType.DOCUMENT_TEMPLATE)
        .map((category) => {
          return {
            title: category.name,
            key: category.key,
            value: category.category_pk,
          };
        });
    },
    projectStatusCategories: (state) => {
      return state.allCategories
        .filter((category) => category.type === CategoryType.PROJECT_STATUS)
        .map((category) => {
          return {
            title: category.name,
            key: category.key,
            value: category.category_pk,
          };
        });
    },
  },
});
