<script setup>
import BaseDashboard from "@/@core/components/dashboard/BaseDashboard.vue";
import NavBar from "@/@core/components/NavBar.vue";

definePage({
  meta: {
    layout: "blank",
  },
});

// Store instances

const dashboardTabs = [
  {
    key: "google_ads",
    title: "Google Ads",
    icon: "mdi-view-dashboard",
  },
  {
    key: "google_analytics",
    title: "Google Analytics",
    icon: "mdi-database",
  },
];

const activeTab = ref("google_ads");

const baseCardConfig = [
  {
    title: "Google Analytics",
    company: "Client Ltd",
    reportType: "Detailed Report",
    type: "title",
    columns: 12,
  },
  {
    title: "Overview",
    content:
      "This section offers a comprehensive overview of your Google Analytics performance, summarizing essential metrics and trends that highlight the effectiveness of your website. It provides a snapshot of how your website is performing, showcasing key insights such as page views, bounce rate, average session duration, and conversion rate. By analyzing these data points, you can identify areas of success, spot opportunities for improvement, and make informed decisions to optimize your website for better results.",
    type: "overview",
    columns: 12,
  },
  {
    cardKey: "ad_group_performance",
    chartType: "line_chart",
    columns: 6,
  },
  {
    cardKey: "campaign_performance",
    chartType: "bar_chart",
    columns: 6,
  },
  {
    title: "Overview",
    content:
      "This section offers a comprehensive overview of your Google Analytics performance, summarizing essential metrics and trends that highlight the effectiveness of your website. It provides a snapshot of how your website is performing, showcasing key insights such as page views, bounce rate, average session duration, and conversion rate. By analyzing these data points, you can identify areas of success, spot opportunities for improvement, and make informed decisions to optimize your website for better results.",
    type: "overview",
    columns: 12,
  },
  {
    cardKey: "ctr_by_ad_network",
    chartType: "line_chart",
    columns: 4,
  },
  {
    cardKey: "average_cpc_over_time",
    chartType: "bar_chart",
    columns: 4,
  },
  {
    cardKey: "conversion_rate_by_campaign",
    chartType: "line_chart",
    columns: 4,
  },
  {
    cardKey: "impressions_by_device",
    chartType: "line_chart",
    columns: 6,
  },
  {
    cardKey: "ctr_by_ad_network",
    chartType: "line_chart",
    columns: 4,
  },
  {
    cardKey: "conversions_by_device",
    chartType: "line_chart",
    columns: 6,
  },
];

// Initialize on load
</script>
<template>
  <!-- Main Container -->
  <div
    id="center-div"
    class="rounded-2xl bg-white shadow-lg flex flex-col h-screen"
  >
    <!-- Navbar -->
    <NavBar />

    <!-- Main Content -->
    <div class="flex flex-grow">
      <!-- Left Column -->
      <div class="flex-grow bg-gray-100 overflow-auto">
        <div class="px-10 py-4 h-full">
          <!-- Dynamically render components based on filteredTabs -->
          <BaseDashboard :card-config="baseCardConfig" />
        </div>
      </div>
    </div>
  </div>
</template>
