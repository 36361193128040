<script setup>
import atrLogo from "@images/logos/atr.png";

import { passwordValidator } from "@/@core/utils/validators";
import { useAuthStore } from "@/composables/store/useAuthStore";

const router = useRouter();
const route = useRoute();

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// Get random name
const randomId = () => {
  return Math.random().toString(36).substr(2, 3) + "Agency";
};

const random = randomId();

// Get url params and set default values
const {
  company_name: routeCompanyName,
  user_email: routeUserEmail,
  company_type: routeCompanyType,
  company_website: routeCompanyWebsite,
  user_type: routeUserType,
} = route.query;

const env = import.meta.env.VITE_ENV;

const form = ref({
  name: env === "development" ? random : "",
  email:
    routeUserEmail || (env === "development" ? `${random}@example.com` : ""),
  password: env === "development" ? "Soccer12" : "",
  companyName: routeCompanyName || (env === "development" ? random : ""),
  companyWebsite:
    routeCompanyWebsite || (env === "development" ? random + ".com" : ""),
  companyType: routeCompanyType || "agency",
  userType: routeUserType || null,
  privacyPolicies: false,
});

const { isSignupLoading, updateSignupUserData, signUp, signup } =
  useAuthStore();

const refVForm = ref(null);

const isPasswordVisible = ref(false);

const onSubmit = async () => {
  const isValid = await refVForm.value.validate();
  if (!isValid.valid) return;

  updateSignupUserData({
    name: form.value.name,
    email: form.value.email,
    password: form.value.password,
    company_name: form.value.companyName,
    company_website: form.value.companyWebsite,
    company_type: form.value.companyType,
  });

  await signUp();

  await nextTick(() => {
    console.log("router", router);
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};
</script>

<template>
  <VForm ref="refVForm" class="auth-form-wrapper" @submit.prevent="onSubmit">
    <VRow no-gutters class="auth-wrapper bg-surface">
      <!-- Left Column (Image) -->
      <VCol md="7" class="d-none d-md-flex auth-left-column">
        <div class="left-content">
          <h1 class="left-title">
            Start Your Free 14-Day Trial Today<br />
            No Credit Card, No Commitment
            <br />
            <span class="font-bold italic">Just Results</span>
          </h1>
          <p class="left-description">
            Experience the power of seamless client onboarding. Sign up now to
            streamline access, improve workflows, and manage clients
            effortlessly.
          </p>
        </div>
      </VCol>

      <!-- Right Column (Form) -->
      <VCol cols="12" md="5" class="auth-right-column">
        <VCard flat class="auth-card">
          <VCardText class="auth-content">
            <VImg class="auth-logo" :src="atrLogo" alt="ATR Logo" />
            <p class="auth-subtitle">Create account to continue.</p>

            <VRow class="auth-form">
              <!-- Username -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.name"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator]"
                  autofocus
                  label="Name"
                  placeholder="Johndoe"
                />
              </VCol>

              <!-- email -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.email"
                  :disabled="isSignupLoading || routeUserEmail"
                  :rules="[requiredValidator, emailValidator]"
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- Company Name -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.companyName"
                  :disabled="isSignupLoading || routeCompanyName"
                  :rules="[requiredValidator]"
                  label="Company Name"
                  placeholder="John Company Co."
                />
              </VCol>

              <!-- Company Website -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.companyWebsite"
                  :disabled="isSignupLoading || routeCompanyWebsite"
                  :rules="[requiredValidator]"
                  label="Company Website"
                  placeholder="attherate.com"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator, passwordValidator]"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <VCol cols="12">
                <div class="privacy-policy">
                  <VCheckbox
                    id="privacy-policy"
                    v-model="form.privacyPolicies"
                    :rules="[requiredValidator]"
                    inline
                  >
                    <template #label>
                      <VLabel for="privacy-policy" style="opacity: 1">
                        <span target="_blank" class="me-1">I agree to</span>
                        <a
                          href="https://www.attherate.ai/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-primary"
                          >privacy policy</a
                        >
                        <span class="mx-1">and</span>
                        <a
                          href="https://www.attherate.ai/terms-of-use"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-primary"
                          >terms of use</a
                        >
                      </VLabel>
                    </template>
                  </VCheckbox>
                </div>
                <VBtn :loading="signup.isLoading" block type="submit">
                  Sign up
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol cols="12" class="auth-footer">
                <span>Already have an account?</span>
                <RouterLink class="text-primary ms-2" :to="{ name: 'login' }">
                  Sign in instead
                </RouterLink>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VForm>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.auth-left-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgb(var(--v-theme-primary));
  color: white;
  min-block-size: 100vh;
}

.left-content {
  max-inline-size: min(600px, 90%);
  text-align: start;
}

.left-title {
  font-size: clamp(32px, 5vw, 48px);
  font-weight: 400;
  line-height: 1.2;
  margin-block-end: 20px;
}

.left-description {
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.5;
  margin-block-end: 40px;
}

.auth-right-column {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-surface));
  min-block-size: 100vh;
}

.auth-card {
  inline-size: 100%;
  margin-block: 1rem;
  max-inline-size: min(500px, 90%);
}

.auth-content {
  padding: 1rem;

  @media (max-width: 600px) {
    padding: 0.75rem;
  }
}

.auth-logo {
  margin-block-end: 1rem;
  max-inline-size: 200px;

  @media (max-width: 600px) {
    margin-block-end: 0.75rem;
    max-inline-size: 150px;
  }
}

.auth-subtitle {
  color: var(--gray-600, #535862);
  font-size: 16px;
  margin-block-end: 1rem;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-block-end: 0.75rem;
  }
}

.auth-form {
  gap: 0.5rem;
}

:deep(.v-col) {
  padding-block: 4px;
}

.privacy-policy {
  display: flex;
  align-items: center;
  margin-block: 0.75rem 1rem;
}

.auth-footer {
  margin-block-start: 1rem;
  text-align: center;
}

// Fix for smaller screens
@media (max-width: 960px) {
  .auth-left-column {
    display: none;
  }

  .auth-right-column {
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .auth-wrapper {
    padding: 0.5rem;
  }
}

.auth-form-wrapper {
  overflow: hidden;
  block-size: 100vh;
}
</style>
