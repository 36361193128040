<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import AuthProvider from "@/views/pages/authentication/AuthProvider.vue";
import LoginImg from "@images/pages/login.png";
import { VForm } from "vuetify/components/VForm";

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// const posthog = inject("posthog");

const environment =
  import.meta.env.VITE_ENV === "development" ? "Development" : null;

const isPasswordVisible = ref(false);
const route = useRoute();
const router = useRouter();
const ability = useAbility();
const { signIn, updateSigninUserData, signin, googleCallback } = useAuthStore();

const googleAuthCode = ref(route.query.code);

const errors = ref({
  email: undefined,
  password: undefined,
});

const refVForm = ref();

const user = ref({
  // email: "suzaliikmal@gmail.com",
  // password: "Soccer12",
  // email: "test1234@gmail.com",
  // password: "Soccer12",
});

// const rememberMe = ref(false)

const onSubmit = async () => {
  const isValid = await refVForm.value?.validate();
  if (!isValid.valid) return;

  updateSigninUserData({
    email: user.value.email,
    password: user.value.password,
  });

  await signIn();

  await nextTick(() => {
    console.log("router", router);
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};

const init = async () => {
  try {
    if (googleAuthCode.value) {
      const googleCallbackResponse = await googleCallback(googleAuthCode.value);
      if (googleCallbackResponse.data.redirect === "signup") {
        router.push({
          name: "register",
          query: {
            user_email: googleCallbackResponse.data.email,
            name: googleCallbackResponse.data.name,
          },
        });
      } else {
        router.push("/");
      }
    } else {
      router.push("/");
    }
  } catch (error) {
    router.push("/");
  }
};

console.log("Created posthog", posthog); //posthog accessible anywhere!

init();
</script>

<template>
  <VRow no-gutters class="auth-wrapper bg-surface">
    <!-- Left Column -->
    <VCol cols="12" md="5" class="auth-left-column">
      <VCard flat :elevation="2" class="auth-card">
        <VCardText class="auth-content mx-10">
          <img
            class="auth-logo"
            src="../assets/images/pages/login-logo.png"
            alt="Login Logo"
          />
          <div class="auth-header">
            <p class="auth-title">Log in</p>
            <p class="auth-subtitle">
              Welcome back! Please enter your details.
            </p>
          </div>

          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <div class="auth-form">
              <div class="auth-fields">
                <AppTextField
                  v-model="user.email"
                  :disabled="signin.isLoading"
                  label="Email"
                  placeholder="Enter your Email"
                  type="email"
                  autofocus
                  :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email"
                />

                <!-- password -->
                <AppTextField
                  v-model="user.password"
                  label="Password"
                  :disabled="signin.isLoading"
                  placeholder="********"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div class="">
                  <!-- add a checkbox with remeber me btn? -->

                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    Forgot Password?
                  </RouterLink>
                </div>

                <VBtn :loading="signin.isLoading" block type="submit">
                  <p class="text-sm">Login</p>
                </VBtn>
              </div>

              <div class="auth-divider">
                <VDivider color="black" />
                <p>Or Login with</p>
                <VDivider />
              </div>

              <div class="text-center">
                <AuthProvider :is-google-btn-loading="!!googleAuthCode" />
              </div>
            </div>
          </VForm>

          <div class="auth-footer">
            <span>Don't have an account?</span>
            <RouterLink class="text-primary ms-2" :to="{ name: 'register' }">
              Create now
            </RouterLink>
          </div>
        </VCardText>
      </VCard>
    </VCol>

    <!-- Right Column -->
    <VCol md="7" class="d-none d-md-flex auth-right-column">
      <div class="solid-circle"></div>
      <div class="right-content">
        <VImg :src="LoginImg" alt="App Screenshot" class="auth-screenshot" />
        <h1 class="right-title">
          One click to link with Google Ads, Google Analytics, Facebook Ads,
          Instagram, LinkedIn
        </h1>
        <p class="right-description">
          Log in to manage your clients, streamline workflows, and access secure
          permissions—all from one dashboard.
        </p>
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.auth-left-column {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary));
  min-block-size: 100vh;
}

.auth-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  block-size: 100vh;
  inline-size: 100%;
}

.auth-content {
  padding: 1rem;
  inline-size: min(80%, 450px);
  margin-block: 0;
  margin-inline: auto;

  @media (max-width: 600px) {
    inline-size: 100%;
    padding-block: 1rem;
    padding-inline: 0.5rem;
  }
}

.auth-logo {
  block-size: 50px;
  inline-size: 200px;
  margin-block-end: 50px;

  @media (max-width: 600px) {
    block-size: 38px;
    inline-size: 150px;
    margin-block-end: 30px;
  }
}

.auth-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-block-end: 20px;
}

.auth-title {
  color: var(--gray-900, #181d27);
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 44px;

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.auth-subtitle {
  color: var(--gray-600, #535862);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.auth-form {
  inline-size: 100%;
}

.auth-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block-end: 20px;
}

.auth-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-block: 1.5rem;
  margin-inline: 0;
}

.auth-footer {
  margin-block-start: 1.5rem;
  text-align: center;
}

.auth-right-column {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgb(var(--v-theme-primary));
  color: white;
  min-block-size: 100vh;
}

.right-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-inline-size: 80%;
}

.auth-screenshot {
  inline-size: 100%;
  margin-block-end: 30px;
}

.right-title {
  font-size: clamp(28px, 4vw, 40px);
  font-weight: 800;
  line-height: 1.25;
  margin-block-end: 20px;
  text-align: center;
}

.right-description {
  font-size: clamp(16px, 2vw, 20px);
  margin-block-end: 40px;
  text-align: start;
}

.solid-circle {
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 50%);
  block-size: min(1000px, 100vw);
  inline-size: min(1000px, 100vw);
  inset-block-start: -500px;
  inset-inline-end: -500px;
}

// Fix for smaller screens
@media (max-width: 960px) {
  .auth-right-column {
    display: none;
  }
}

@media (max-width: 600px) {
  .auth-wrapper {
    padding: 1rem;
  }
}
</style>
