<!-- Dashboard Page Component -->

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import BaseDashboard from "@/@core/components/dashboard/BaseDashboard.vue";
import NavBar from "@/@core/components/NavBar.vue";
import VueDatePicker from "@vuepic/vue-datepicker";

import { useDashboardStore } from "@/composables/store/useDashboardStore";
import { useReportStore } from "@/composables/store/useReportStore";

import { useAuthStore } from "@/composables/store/useAuthStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";
import googleAds from "@/utils/dashboard/google_ads";
import googleAdsPerformance1 from "@/utils/dashboard/google_ads_performance_1";
import googleAdsPerformance2 from "@/utils/dashboard/google_ads_performance_2";
import googleAnalytics from "@/utils/dashboard/google_analytics";
import metaAds from "@/utils/dashboard/meta_ads";

definePage({
  meta: {
    layout: "blank",
  },
});

const dashboardStore = useDashboardStore();
const { selectedDashboard } = storeToRefs(dashboardStore);
const reportStore = useReportStore();
const route = useRoute();
const companyId = route.query.company_id;

// Default date range (7 days ago to today)
const dateRange = ref([
  new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
  new Date(), // Today
]);

const companyName = computed(
  () => dashboardStore?.selectedCompany?.name || "Sample Company"
);

// Watch for date range changes
watch(dateRange, (value) => {
  const startDate = value[0].toISOString().split("T")[0]; // Format start date as YYYY-MM-DD
  const endDate = value[1].toISOString().split("T")[0]; // Format end date as YYYY-MM-DD

  // Update dashboardStore's filters and individual date properties
  dashboardStore.filters.dateRange = `${startDate}~${endDate}`;
  dashboardStore.startDate = startDate;
  dashboardStore.endDate = endDate;
});

// Store instances
const selectedAccountId = ref(null);
const baseCardConfig = ref([]);
const isLoading = ref(false);

const dashboardMapper = {
  google_analytics_1: googleAnalytics,
  google_ads_1: googleAds,
  meta_ads_1: metaAds,
  google_ads_performance_1: googleAdsPerformance1,
  google_ads_performance_2: googleAdsPerformance2,
};

// Store the propertyIds for the selected account
const selectedAccountPropertyIds = ref([]);

const areAllDropdownsSelected = computed(() => {
  const isGoogleAnalyticsValid =
    filteredPlatforms.value.includes("google_analytics") &&
    selectedAccountId.value &&
    dashboardStore.property_id;

  const isGoogleAdsValid =
    filteredPlatforms.value.includes("google_ads") &&
    dashboardStore.customer_id;

  const isMetaAdsValid =
    filteredPlatforms.value.includes("meta_ads") && dashboardStore.account_id;

  return (
    selectedReportKey.value &&
    (isGoogleAnalyticsValid || isGoogleAdsValid || isMetaAdsValid)
  );
});

const selectedReportKey = computed(() => {
  // First check URL query parameter
  const urlDashboardType = route.query.dashboard_type;
  if (urlDashboardType) return urlDashboardType;

  // Fallback to existing logic
  return selectedDashboard.value?.dashboard?.key || null;
});

const selectedReportName = computed(() => {
  // First check URL query parameter
  const dashboardType = route.query.dashboard_type;
  if (dashboardType) {
    const dashboard =
      reportStore.companyDashboards?.[0]?.company_dashboards.find(
        (dashboard) => dashboard.dashboard.key === dashboardType
      );

    return dashboard?.dashboard?.name || null;
  }

  // Fallback to existing logic
  return selectedDashboard.value?.dashboard?.name || null;
});

const authStore = useAuthStore();

// Add new refs for access control
const isEditingDisabled = ref(false);
const accessType = ref("full");

// Modified init function to handle API key and URL parameters
const init = async () => {
  const {
    api_key,
    dashboard_type,
    start_date,
    end_date,
    property_id,
    access_type,
  } = route.query;

  // Handle API key authentication if present
  if (api_key) {
    try {
      await authStore.regenerateTokenByApiKey(api_key);

      // Set access type from URL
      accessType.value = access_type || "full";
      isEditingDisabled.value = access_type === "limited";

      // Set date range from URL params
      if (start_date && end_date) {
        dateRange.value = [new Date(start_date), new Date(end_date)];
      }

      // Auto-select dashboard type and disable its editing
      if (dashboard_type) {
        dashboardStore.filters.dashboardType = dashboard_type;
        await reportStore.getAllSystemDashboards();

        // Set property ID if provided
        if (property_id) {
          dashboardStore.property_id = property_id;
        }
      }
    } catch (error) {
      console.error("Error authenticating with API key:", error);
      errorStore.setErrorMessage("Invalid API key or authentication failed");

      return;
    }
  } else {
    // Original init logic for non-API key access
    const startDate = dateRange.value[0].toISOString().split("T")[0];
    const endDate = dateRange.value[1].toISOString().split("T")[0];

    dashboardStore.startDate = startDate;
    dashboardStore.endDate = endDate;
    dashboardStore.filters.dateRange = `${startDate}~${endDate}`;

    if (dashboard_type) {
      dashboardStore.filters.dashboardType = dashboard_type;
    }

    if (route.query.company_id) {
      await reportStore.getAllSystemDashboards();
      await reportStore.getCompaniesWithDashboardsByClient(
        route.query.company_id
      );
      await dashboardStore.getSelectedCompanyDashboards(route.query.company_id);

      if (!route.query.dashboard_id) {
        dashboardStore.filters.dashboardType =
          dashboardStore.companyDashboards?.[0]?.company_dashboards_pk;
      }
      await reportStore.getAllConnectedAccountsByCompany(
        route.query.company_id
      );
    }
  }

  // Initialize dashboards
  if (selectedReportKey.value) {
    baseCardConfig.value = dashboardMapper[selectedReportKey.value];
  }
};

// Filtered platforms for conditional rendering
const filteredPlatforms = computed(() => {
  return selectedDashboard.value
    ? selectedDashboard.value.dashboard?.platforms || []
    : [];
});

// Watch for changes in the selected report key and update baseCardConfig with loading state
watch(selectedReportKey, async (newValue) => {
  isLoading.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));
  if (newValue) {
    baseCardConfig.value = dashboardMapper[newValue];
  }
  isLoading.value = false;
});

// Watch for changes in selected Google Analytics account
watch(selectedAccountId, (newAccountId) => {
  if (!newAccountId || !filteredGoogleAnalyticsIds.value) return;

  // Clear the property ID when account changes
  dashboardStore.property_id = null;

  // Find the selected account to retrieve its propertyIds
  const selectedAccount = filteredGoogleAnalyticsIds.value.find(
    (account) => account.id === newAccountId
  );

  // If account is found, set the propertyIds for the new autocomplete
  if (selectedAccount) {
    selectedAccountPropertyIds.value = selectedAccount.propertyIds || [];
  }
});

// Fetch filtered Google Analytics IDs
const filteredGoogleAnalyticsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === companyId
  );

  return filteredAccounts?.[0]?.connected_accounts?.google_analytics || [];
});

// Fetch filtered Google Ads IDs
const filteredGoogleAdsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === companyId
  );

  return filteredAccounts?.[0]?.connected_accounts?.google_ads || [];
});

// Fetch filtered Meta Ads IDs
const filteredMetaAdsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === companyId
  );

  return filteredAccounts?.[0]?.connected_accounts?.meta_ads || [];
});

const isPrinting = ref(false);

// Backend PDF generation
const generatePdfBackend = async () => {
  isPrinting.value = true;
  try {
    const response = await dashboardStore.generatePdf();

    // Convert the response to a blob and download
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.href = url;
    a.download = `${companyName.value}-${dateRange.value[0].toISOString().split("T")[0]}-${dateRange.value[1].toISOString().split("T")[0]}.pdf`;
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("PDF generation failed:", error);
  } finally {
    isPrinting.value = false;
  }
};

// Add these new refs and functions
const isGeneratingLink = ref(false);
const shareDialog = ref(false);
const generatedLink = ref("");
const selectedAccessType = ref("full");

// Add a watch for selectedAccessType
watch(selectedAccessType, (newAccessType) => {
  if (generatedLink.value) {
    const url = new URL(generatedLink.value);

    url.searchParams.set("access_type", newAccessType);
    generatedLink.value = url.toString();
  }
});

const generateShareLink = async () => {
  isGeneratingLink.value = true;
  try {
    const payload = {
      company_id: companyId,
      dashboard_type: selectedReportKey.value,
      start_date: dateRange.value[0].toISOString().split("T")[0],
      end_date: dateRange.value[1].toISOString().split("T")[0],
      access_type: selectedAccessType.value,
      filters: {
        property_id: dashboardStore.property_id,
        customer_id: dashboardStore.customer_id,
        account_id: dashboardStore.account_id,
      },
    };

    const response = await dashboardStore.generateShareLink(payload);

    generatedLink.value = response.share_url;
    shareDialog.value = true;
  } catch (error) {
    console.error("Failed to generate share link:", error);
  } finally {
    isGeneratingLink.value = false;
  }
};

// Add these new computed properties
const isLinkValid = computed(() => {
  if (selectedAccessType.value === "full") return true;

  // For limited access, validate based on selected platforms
  if (
    filteredPlatforms.value.includes("google_analytics") &&
    !dashboardStore.property_id
  )
    return false;
  if (
    filteredPlatforms.value.includes("google_ads") &&
    !dashboardStore.customer_id
  )
    return false;
  if (
    filteredPlatforms.value.includes("meta_ads") &&
    !dashboardStore.account_id
  )
    return false;

  return true;
});

const requiredSelections = computed(() => {
  if (selectedAccessType.value !== "limited") return [];

  const required = [];
  if (
    filteredPlatforms.value.includes("google_analytics") &&
    !dashboardStore.property_id
  ) {
    required.push("Google Analytics Property ID");
  }
  if (
    filteredPlatforms.value.includes("google_ads") &&
    !dashboardStore.customer_id
  ) {
    required.push("Google Ads Customer ID");
  }
  if (
    filteredPlatforms.value.includes("meta_ads") &&
    !dashboardStore.account_id
  ) {
    required.push("Meta Ads Account ID");
  }

  return required;
});

// Add a watch for property_id changes
watch(
  () => dashboardStore.property_id,
  async (newValue) => {
    if (newValue && selectedReportKey.value) {
      isLoading.value = true;
      await new Promise((resolve) => setTimeout(resolve, 500));
      baseCardConfig.value = dashboardMapper[selectedReportKey.value];
      isLoading.value = false;
    }
  }
);

// Add a watch for customer_id changes
watch(
  () => dashboardStore.customer_id,
  async (newValue) => {
    if (newValue && selectedReportKey.value) {
      isLoading.value = true;
      await new Promise((resolve) => setTimeout(resolve, 500));
      baseCardConfig.value = dashboardMapper[selectedReportKey.value];
      isLoading.value = false;
    }
  }
);

// Initialize the component
init();
</script>

<template>
  <div
    id="center-div"
    class="rounded-2xl bg-white shadow-lg flex flex-col h-screen"
  >
    <!-- Navbar -->
    <NavBar />

    <!-- Main Content -->
    <div class="flex flex-grow">
      <!-- Left Column -->
      <div class="flex-grow bg-gray-100 overflow-auto">
        <div class="px-10 py-4 h-full bg-white">
          <div class="flex items-center justify-between">
            <div class="flex flex-col gap-1">
              <h2 class="text-gray-900 font-semibold text-2xl">
                {{ companyName }}
              </h2>
            </div>

            <!-- Conditional rendering based on companyId -->
            <div v-if="companyId" class="flex items-center gap-4">
              <VueDatePicker
                v-model="dateRange"
                label="Date Range"
                placeholder="Select date"
                range
                hide-input-icon
                auto-apply
                :clearable="false"
                format="yyyy-MM-dd"
                color="#ffcc00"
                style="inline-size: 240px"
                calendar-class="custom-datepicker"
              />
              <!-- Autocomplete for selecting Report Type -->
              <VAutocomplete
                v-model="selectedDashboard"
                class="bg-white"
                style="inline-size: 220px"
                :items="reportStore.companyDashboards?.[0]?.company_dashboards"
                return-object
                item-title="dashboard.name"
                item-value="dashboard.key"
                placeholder="Select Report Type"
                outlined
                clearable
              />
            </div>
          </div>

          <div class="flex justify-end gap-1 mt-5 w-full">
            <div class="flex items-center gap-4">
              <VAutocomplete
                v-if="filteredPlatforms.includes('google_analytics')"
                v-model="selectedAccountId"
                class="bg-white"
                style="inline-size: 220px"
                :items="filteredGoogleAnalyticsIds"
                item-title="name"
                item-value="id"
                placeholder="Select Google Analytics"
                outlined
                clearable
                :disabled="isEditingDisabled || route.query.dashboard_type"
              />
              <VAutocomplete
                v-if="selectedAccountPropertyIds.length"
                v-model="dashboardStore.property_id"
                class="bg-white"
                style="inline-size: 220px"
                :items="selectedAccountPropertyIds"
                item-title="id"
                item-value="id"
                placeholder="Select Property ID"
                outlined
                clearable
                :disabled="isEditingDisabled || route.query.dashboard_type"
              />

              <VAutocomplete
                v-if="filteredPlatforms.includes('google_ads')"
                v-model="dashboardStore.customer_id"
                class="bg-white"
                style="inline-size: 220px"
                :items="filteredGoogleAdsIds"
                item-title="name"
                item-value="id"
                placeholder="Select Customer ID"
                outlined
                clearable
                :disabled="isEditingDisabled || route.query.dashboard_type"
              />
              <VAutocomplete
                v-if="filteredPlatforms.includes('meta_ads')"
                v-model="dashboardStore.account_id"
                class="bg-white"
                style="inline-size: 220px"
                :items="filteredMetaAdsIds"
                item-title="label"
                item-value="value"
                placeholder="Select Account ID"
                outlined
                clearable
                :disabled="isEditingDisabled || route.query.dashboard_type"
              />
            </div>
          </div>

          <div
            v-if="!areAllDropdownsSelected && companyId"
            class="flex justify-center mt-10 w-full h-1/2 bg-white items-center"
          >
            <p class="text-gray-500 text-lg">
              Please select all dropdowns to view the dashboard.
            </p>
          </div>

          <div v-else-if="isLoading" class="flex justify-center mt-10">
            <!-- Loading spinner -->
            <VProgressCircular color="primary" indeterminate />
          </div>

          <div v-else class="mt-10">
            <div class="flex justify-end items-center gap-4">
              <VBtn
                :loading="isPrinting"
                color="white"
                @click="generatePdfBackend"
              >
                Download PDF
              </VBtn>
              <!--
                <VBtn
                v-if="companyId"
                :loading="isGeneratingLink"
                color="primary"
                @click="generateShareLink"
                >
                Share
                </VBtn> 
              -->
            </div>

            <div id="dashboard-content" class="mt-10">
              <BaseDashboard
                :card-config="baseCardConfig"
                :fake-data="!companyId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Update the dialog component -->
    <VDialog v-model="shareDialog" max-width="600px">
      <VCard>
        <VCardTitle>Share Dashboard</VCardTitle>
        <VCardText>
          <VSelect
            v-model="selectedAccessType"
            :items="[
              { title: 'Full Access', value: 'full' },
              { title: 'Limited Access', value: 'limited' },
            ]"
            label="Access Type"
            class="mb-4"
          />

          <!-- Full Access Info -->
          <VAlert
            v-if="selectedAccessType === 'full'"
            color="success"
            variant="tonal"
            class="mb-4"
          >
            <p class="my-2">This link will provide access to:</p>
            <ul class="ml-4">
              <li>All available dashboard reports</li>
              <li>All connected accounts and properties</li>
              <li>Full data access across all connected platforms</li>
            </ul>
          </VAlert>

          <!-- Limited Access Info -->
          <template v-else>
            <!-- Required Selections Warning -->
            <VAlert
              v-if="requiredSelections.length > 0"
              color="warning"
              variant="tonal"
              class="mb-4"
            >
              <p class="my-2">
                Please select the following to generate a limited access link:
              </p>
              <ul class="ml-4">
                <li v-for="item in requiredSelections" :key="item">
                  {{ item }}
                </li>
              </ul>
            </VAlert>

            <!-- Selected Options Info -->
            <VAlert v-else color="info" variant="tonal" class="mb-4">
              <p class="my-2">This link will provide access to:</p>
              <ul class="ml-4">
                <li>Dashboard: {{ selectedReportName }}</li>
                <li v-if="dashboardStore.property_id">
                  Property ID: {{ dashboardStore.property_id }}
                </li>
                <li v-if="dashboardStore.customer_id">
                  Customer ID: {{ dashboardStore.customer_id }}
                </li>
                <li v-if="dashboardStore.account_id">
                  Account ID: {{ dashboardStore.account_id }}
                </li>
              </ul>
            </VAlert>
          </template>

          <VTextField
            v-model="generatedLink"
            label="Share Link"
            readonly
            :disabled="!isLinkValid"
            :hint="
              !isLinkValid
                ? 'Please select required fields for limited access'
                : ''
            "
            :persistent-hint="!isLinkValid"
            append-icon="tabler-copy"
            @click:append="
              () => {
                if (isLinkValid) {
                  useSuccessStore().setSuccessMessage(
                    'Link copied to clipboard'
                  );
                  navigator.clipboard.writeText(generatedLink);
                }
              }
            "
          />
        </VCardText>
        <VCardActions>
          <VSpacer />
          <VBtn color="primary" @click="shareDialog = false">Close</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<style>
/* Print styles */
@media print {
  #center-div {
    overflow: visible !important;
    block-size: auto !important;
  }

  .no-print {
    display: none !important;
  }

  #dashboard-content {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
